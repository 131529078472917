import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const clientID = Math.round(Math.random() * 10 ** 16);
const initialState: ReplayConfig = {
  isReplay: false,
  // startDate: DateTime.now().minus({minute: 30}).set({second: 0, millisecond: 0}).toISO(),
  // endDate: DateTime.now().set({second: 0, millisecond: 0}).toISO(),
  // timestamp: DateTime.now().minus({minute: 30}).set({second: 0, millisecond: 0}).toISO(),
  startDate: 1672783425615,
  endDate: 1672783444615,
  // timestamp: DateTime.fromISO("2023-01-03T22:03:46.615447").toMillis(),
  lastTimestamp: 1672783425615,
  started: Date.now(),
  running: false,
  name: "Example Replay",
  controlsOpen: false,
  firstLoad: true,
  currentIMEIs: [],
  clientID: clientID,
  isFromRemote: false,
};

export const replaySlice = createSlice({
  name: "replay",
  initialState: initialState,
  reducers: {
    set: (state, data: PayloadAction<ReplayConfig>) => {
      state = { ...data.payload, clientID };
      return state;
    },
    setTimestamp: (state, data: PayloadAction<number>) => {
      state.lastTimestamp = data.payload;
      return state;
    },
    update: (state, data: PayloadAction<Partial<ReplayConfig>>) => {
      const newRunningState = !state.running;

      state = {
        ...state,
        started: newRunningState ? Date.now() : state.started,
        ...data.payload,
        clientID,
      };
      // const startDT = DateTime.fromMillis(state.startDate)
      // const endDT = DateTime.fromMillis(state.endDate)
      // const interval = Interval.fromDateTimes(startDT, endDT)
      // if(interval.toDuration().as("minutes") > 30) state.endDate = startDT.plus({minute: 30}).toMillis()
      // console.log(interval)
      // console.log(startDT.toISO())
      // console.log(endDT.toISO())
      return state;
    },
    toggle: (state, data) => {
      state.isReplay = data.payload;
      return state;
    },
    toggleControls: (state, data) => {
      state.controlsOpen = data.payload;
      return state;
    },
    togglePlaying: (state, data?: PayloadAction<boolean | undefined>) => {
      const newRunningState = !state.running;
      state = {
        ...state,
        lastTimestamp: calculateTimestamp(state).toMillis(),
        running: data?.payload ?? newRunningState,
        firstLoad: false,
        clientID,
      };
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set,
  update,
  toggle,
  toggleControls,
  togglePlaying,
  setTimestamp,
} = replaySlice.actions;

export default replaySlice.reducer;

export interface ReplayConfig {
  isReplay: boolean;
  startDate: number;
  endDate: number;
  lastTimestamp: number;
  started: number;
  running: boolean;
  name: string;
  controlsOpen: boolean;
  firstLoad: boolean;
  currentIMEIs: string[];
  clientID?: number;
  isFromRemote: boolean;
}

export function calculateTimestamp(config: ReplayConfig): DateTime {
  // console.log(config)
  if (!config.running) {
    return DateTime.fromMillis(config.lastTimestamp);
  }
  const sinceStart = Date.now() - config.started;
  const timestamp = config.lastTimestamp + sinceStart;
  if (timestamp >= config.endDate) {
    return DateTime.fromMillis(config.endDate);
  }
  return DateTime.fromMillis(timestamp);
}
